<template>
    <div class="app flex-row align-items-center">
      <div class="container">
        <h2>{{ $t('tagItemRecalculationList') }}</h2>
        <grid-view-table
          :id="'tag-item-price'"
          :columns="columns"
          :path="'tag-item-price'"
          :add-route="'AddTagItemRecalculation'"
          :add-route-params="{tagItemId}"
          :add-text="$t('addTagItemRecalculation')"
          :extra-params="`&join=tagItem&filter=tagItem.id||$eq||${tagItemId}`"
        />
      </div>
    </div>
  </template>
  
  <script>
    import GridViewTable from '../../components/GridViewTable';
    import { columns } from './grid-views/tagItemRecalculations';
  
    export default {
      name: 'TagItemRecalculationList',
      props: ['tagItemId'],
      components: {
        GridViewTable,
      },
      data: () => {
        return {
          columns,
        }
      }
    }
  </script>