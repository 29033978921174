import { actions } from "../../../components/grid-views/standart-actions-without-view";
import i18n from "@/plugins/i18n";
import moment from "moment";

export const columns = [
  {
    label: 'value',
    field: 'value',
    width: '30%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'status',
    field: 'status',
    width: '35%',
    formatFn: (value) => {
      if (value === 1) {
        return i18n.t('Active');
      }
      return i18n.t('Hidden');
    },
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'date',
    field: 'date',
    width: '35%',
    formatFn: (value) => {
      return moment(value).format('DD.MM.YYYY');
    },
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
]
